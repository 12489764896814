import Cookies from "js-cookie";
import setReferrer from "../utillity/setReferrer.js";

const COOKIE_CONSENT_NAME = "CookieConsent";
const COOKIE_CONSENT_VALUE = "statistics";
export const REFERRER_COOKIE_NAME = "initialTrafficSource";

export default class ReferralHandler {
  constructor() {
    this.consentCookie = this.getConsentCookie();
    this.initialTrafficSourceCookie = this.getInitialTrafficSourceCookie();

    this.init();
  }

  init() {
    window.addEventListener(
      "CookiebotOnAccept",
      this.onConsentGiven.bind(this),
      false
    );

    window.addEventListener(
      "CookiebotOnDecline",
      this.onConsentDeclined.bind(this),
      false
    );
  }

  onConsentGiven() {
    if (Cookiebot.consent[COOKIE_CONSENT_VALUE]) {
      this.addReferrerCookie();
      this.initialTrafficSourceCookie = this.getInitialTrafficSourceCookie();
    }
  }

  onConsentDeclined() {
    if (
      !Cookiebot.consent[COOKIE_CONSENT_VALUE] &&
      this.initialTrafficSourceCookie
    ) {
      this.removeReferrerCookie();
      this.initialTrafficSourceCookie = null;
    }
  }

  getConsentCookie() {
    const cookie = Cookies.get(COOKIE_CONSENT_NAME);
    if (!cookie) return null;

    return this.parseConsentCookie(cookie);
  }

  getInitialTrafficSourceCookie() {
    const cookie = Cookies.get(REFERRER_COOKIE_NAME);
    if (!cookie) return null;

    return this.parseReferrerCookie(cookie);
  }

  parseConsentCookie(cookie) {
    if (cookie) {
      let decodedStr = decodeURIComponent(cookie);

      let fixedStr = decodedStr
        .replace(/'/g, '"') // Replace %27 with double quotes for values
        .replace(/(\w+):/g, '"$1":'); // Add double quotes around keys

      return JSON.parse(fixedStr);
    } else {
      console.error("Error occured when trying to fetch cookie.");
      return null;
    }
  }

  parseReferrerCookie(cookie) {
    if (cookie) {
      let obj = {};
      let pairs = cookie.split("|");

      pairs.forEach((pair) => {
        let [key, value] = pair.split("=");
        obj[key] = value;
      });

      return obj;
    } else {
      console.error("Error occured when trying to fetch cookie.");
      return null;
    }
  }

  addReferrerCookie() {
    setReferrer();
  }

  removeReferrerCookie() {
    Cookies.remove(REFERRER_COOKIE_NAME);
    this.initialTrafficSourceCookie = null;
  }
}
